import get from 'lodash/get';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import { START_SEARCH_THRESHOLD } from '@src/utils/constants/search';
import { createSelector } from 'reselect';

const idLens = item => get(item, ['id']);
const nameLens = item => get(item, ['name']);
const brandNameLens = item => get(item, ['brandName']);
const kcalLens = item => get(item, ['kcal']);
const imageLens = item => get(item, ['image']);
const imageVariantsLens = item => get(item, ['images', '0', 'variants']);
const positionLens = item => get(item, ['position']);
const recipeSearchPendingLens = state => get(state, ['recipeSearchPending']);
const recipeSearchItemsLens = state => get(state, ['recipeSearchItems']);
const recipeSearchInputValueLens = state =>
  get(state, ['recipeSearchInputValue']);
const ratingAverageLens = state => get(state, ['ratingAverage']);
const cookingTimeLens = state => get(state, ['cookingTime']);
const tagsLens = state => get(state, ['tags']);
const tagNameLens = tag => get(tag, ['name']);
const isFavouriteLens = state => get(state, ['isFavourite']);

const recipeDefault = {
  amount: 1,
  unit: 'port'
};

const isSearchValueAboveThreshold = state => {
  const searchInputValue = recipeSearchInputValueLens(state);
  return searchInputValue
    ? searchInputValue.length > START_SEARCH_THRESHOLD - 1
    : false;
};

const isSearchEmpty = state =>
  recipeSearchItemsLens(state).length === 0 &&
  isSearchValueAboveThreshold(state) &&
  !recipeSearchPendingLens(state);

const getPositionDefault = item => positionLens(item) || 0;

const getTags = recipeItem => {
  const tags = tagsLens(recipeItem) || {};

  const flattenedTags = uniqBy(
    flatten(Object.keys(tags).map(item => tags[item])).filter(
      ({ priority }) => priority !== 0
    ),
    'priority'
  )
    .sort((tag1, tag2) => tag2.priority - tag1.priority)
    .slice(0, 2);

  return (
    flattenedTags &&
    flattenedTags.map(item => ({
      name: tagNameLens(item)
    }))
  );
};

const searchResult = items =>
  items.map(item => {
    const image = imageLens(item);

    return {
      foodId: parseInt(idLens(item), 10),
      name: nameLens(item),
      brandName: brandNameLens(item),
      kcalAmount: recipeDefault.amount * kcalLens(item),
      ...recipeDefault,
      logAmount: recipeDefault.amount,
      isRecipe: true,
      hasImage: !!image,
      images: imageVariantsLens(item),
      position: getPositionDefault(item),
      cookingTime: cookingTimeLens(item),
      ratingAverage: ratingAverageLens(item),
      tags: getTags(item),
      isFavourite: isFavouriteLens(item)
    };
  });

const searchResultMemoized = createSelector([recipes => recipes], searchResult);

export default {
  searchResult,
  searchResultMemoized,
  isSearchEmpty,
  isSearchValueAboveThreshold,
  getTags
};
